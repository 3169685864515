import React from 'react';
import './App.css';
import Router from './app/navigation/Router'



function App() {
  return(
    <Router/>
     
  );
}

export default App;
